import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const localeDefault = 'en-my'

const i18n = new VueI18n({
	locale: localeDefault,
	fallbackLocale: localeDefault,
	messages: {
		[localeDefault]: require(`@/assets/translations/${localeDefault}.json`)
	} // fallback language file in local
})

export default i18n
