import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const meta = {
	default: {
		layout: 'layout-default'
	},
	blank: {
		layout: 'layout-blank'
	}
}

// A = Active, G = Guest, I = Inactive, D = Deactivate, L = Locked

const router = new Router({
	// mode: process.env.NODE_ENV === 'development' ? 'hash' : 'history',
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'index',
			component: _ => import('./views/page-landing.vue'),
			meta: { ...meta.default }
		},
		{
			path: '/play/:gid?',
			name: 'play',
			component: () => import('./views/page-play.vue'),
			meta: {
				...meta.default,
				ui: {
					'fab-scroll-top': false, // eslint-disable-line
					'footer': false // eslint-disable-line
				}
			},
			beforeEnter: (to, from, next) => {
				next(to.params.gid ? true : '404')
			}
		},
		/*
		{
			path: '/game_profile/:gid?',
			name: 'game_profile',
			component: () => import('./views/page-game_profile.vue'),
			meta: { ...meta.default },
			beforeEnter: (to, from, next) => {
				next(to.params.gid ? true : '404')
			}
		},
		*/
		{
			path: '/auth',
			component: _ => import('./views/page-auth.vue'),
			meta: { ...meta.blank }
		},
		{
			path: '/popular',
			name: 'popular',
			component: _ => import('./views/page-popular.vue'),
			meta: { ...meta.default }
		},
		{
			path: '/genre/:genre?',
			name: 'genre',
			component: _ => import('./views/page-genre.vue'),
			meta: { ...meta.default }
		},
		/*
		{
			path: '/genre',
			component: _ => import('./views/page-genre.vue'),
			children: [
				{
					path: '',
					component: _ => import('./views/page-all_genres.vue'),
					meta: { ...meta.default }
				},
				{
					path: 'action',
					component: _ => import('./views/page-genre-action.vue'),
					meta: { ...meta.blank }
				}
			]
		},
		*/
		{
			path: '/favourites',
			name: 'favourites',
			component: _ => import('./views/page-favourites.vue'),
			meta: { ...meta.default }
			// unfinished
			// meta: { ...meta.default, grant: ['A', 'I', 'D', 'S', 'L'] }
		},
		{
			path: '/account',
			name: 'account',
			component: _ => import('./views/page-account.vue'),
			meta: {
				...meta.default,
				ui: {
					'footer': false // eslint-disable-line
				},
				grant: ['A', 'I', 'D', 'S', 'L']
			}
		},
		{
			path: '/history',
			name: 'history',
			component: _ => import('./views/page-history.vue'),
			meta: { ...meta.default, grant: ['A', 'I', 'D', 'S', 'L'] }
		},
		{
			path: '/privacy_policy',
			name: 'privacy_policy',
			component: _ => import('./views/page-privacy_policy.vue'),
			meta: { ...meta.default }
		},
		{
			path: '/terms',
			name: 'terms',
			component: _ => import('./views/page-terms.vue'),
			meta: { ...meta.default }
		},
		{
			path: '/faq',
			name: 'faq',
			component: _ => import('./views/page-faq.vue'),
			meta: { ...meta.default }
		},
		{
			path: '*',
			name: '/notFound',
			component: _ => import('./views/page-404.vue'),
			meta: { ...meta.blank }
		}
	]
})

router.afterEach((to, from) => {
	if (process.env.VUE_APP_ENV === 'development') return false

	if (router.app.$ga) router.app.$ga.page(to.path)

	try {
		router.app.$store.dispatch('api/fetch', {
			method: 'POST',
			payload: [{
				visitor: [
					{
						checkin: [
							{ args: [{}] }
						]
					}
				]
			}]
		})
	} catch (err) {}
})

export default router
