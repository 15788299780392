import idb from '@/idb'
import router from '@/router'
import $i18n from '@/i18n'
import * as Cookies from 'js-cookie'

const $db = idb.db

const auth = {
	namespaced: true,
	state: {
		isInitialized: false,
		uid: '',
		t: '',
		s: '',
		status: '',
		type: 'data',
		isDetectionHeaderEnrichmentRequired: true
	},
	getters: {
		isGranted: state => payload => payload.grant === undefined || payload.grant.includes(state.status)
	},
	mutations: {
		SET_AUTH (state, payload) {
			state.uid = payload.uid
			state.t = payload.t
			state.s = payload.s
			state.status = payload.status
			state.type = payload.type || (JSON.parse(process.env.VUE_APP_FLOW_WIFI_IS_SUPPORTED || null) ? 'wifi' : 'data')
			state.isDetectionHeaderEnrichmentRequired = payload.isDetectionHeaderEnrichmentRequired === undefined ? true : payload.isDetectionHeaderEnrichmentRequired
			state.isInitialized = payload.isInitialized === undefined ? true : payload.isInitialized
		}
	},
	actions: {
		async init (store, payload) {
			let result = { code: 2001, description: 'No Auth' }
			let auth

			// use local auth if found
			if (await $db.auth.count() === 1) {
				auth = (await $db.auth.toArray())[0]
			}

			result = await store.dispatch('auth/_set', auth, { root: true })

			return result
		},
		async login (store, payload) {
			let result = await store.dispatch('api/fetch', {
				method: 'POST',
				payload: [{
					user: [{
						get_auth_login: {
							args: {
								msisdn: payload.msisdn,
								psw: payload.password
							}
						}
					}]
				}]
			}, { root: true })

			result = result.user.get_auth_login[0][0]

			if (result.code === 2001) {
				result = await store.dispatch('auth/_set', {
					uid: result.data.suid,
					t: store.state.t,
					s: store.state.s,
					status: result.data.status,
					isDetectionHeaderEnrichmentRequired: false // stays wifi login
				}, { root: true })
			} else {
				// await store.dispatch('auth/_reinit')
			}

			return result
		},
		async logout (store) {
			await store.dispatch('auth/_reinit', null, { root: true })

			if (window.location.pathname === '/') {
				window.location.reload()
			} else {
				router.push({ path: '/', name: 'index' })
			}
		},
		prompt (store, payload = {}) {
			if (store.rootState.auth.status === 'A') return

			if (payload.useNoticeSuspended && store.rootState.auth.status === 'S') {
				store.dispatch('ui/setModal', {
					name: 'modal-notice',
					data: {
						notice: {
							description: {
								path: 'notice_suspended_description',
								args: { brand_name: $i18n.messages[$i18n.locale].brand_name }
							}
						}
					}
				}, { root: true })

				return
			}

			if (payload.useLogin) {
				if (store.rootState.ui.isWifi && !store.rootState.auth.status) {
					store.dispatch('ui/setModal', {
						name: 'modal-login',
						redirect: window.location.pathname
					}, { root: true })

					return
				}
			}

			if (JSON.parse(process.env.VUE_APP_MODAL_AOC_IS_SHOWN || null)) {
				if (!store.rootState.auth.status || ['I'].includes(store.rootState.auth.status)) {
					store.dispatch('ui/setModal', { name: 'modal-advice-of-charge' }, { root: true })
				}
			} else {
				window.location.href = `${process.env.VUE_APP_URL_PORTAL}/${process.env.VUE_APP_URL_PARAMS_PORTAL_SUB}`
			}
		},
		async updateUser (store) {
			await store.dispatch('auth/_awaitAuth', null, { root: true })

			// use local auth if found
			if (await $db.auth.count() === 1) {
				const auth = (await $db.auth.toArray())[0]

				if (auth.uid) {
					const user = (await store.dispatch('users/update', { uids: auth.uid }, { root: true }))[0]
					auth.status = user.status

					await store.dispatch('auth/_set', auth, { root: true })
				}
			}
		},
		async _get (store) {
			const result = { code: 'E4001', description: 'Invalid Auth' }

			if (!store.state.isInitialized) {
				try {
					const result = await store.dispatch('api/fetch', {
						urlParams: process.env.VUE_APP_URL_PARAMS_API_TOKEN
					}, { root: true })

					if (result.code === 2001) {
						result.auth = {
							t: result.token,
							s: result.secret,
							uid: '',
							status: ''
						}

						delete result.token
						delete result.secret
					}

					return result
				} catch (err) {
					console.warn(`[Authentication] failed to get token: ${err}`)
				}
			}

			return result
		},
		async _set (store, payload = {}) {
			// set default
			payload = {
				uid: '',
				status: '',
				type: JSON.parse(process.env.VUE_APP_FLOW_WIFI_IS_SUPPORTED || null) ? 'wifi' : 'data',
				isDetectionHeaderEnrichmentRequired: JSON.parse(process.env.VUE_APP_HEADER_ENRICHMENT_IS_SUPPORTED || null) || false,
				...payload
			}

			let result = { code: 'E4001', description: 'Invalid Auth' }

			// get guest auth if no auth
			if (!payload.t || !payload.s) {
				result = await store.dispatch('auth/_get', null, { root: true })

				if (result.code === 2001) {
					payload = { ...payload, ...result.auth }
				}
			}

			// validate
			result = await store.dispatch('auth/_validate', payload, { root: true })

			if (result.code === 2001) {
				// filter auth
				payload = (({ uid, t, s, status, type, isDetectionHeaderEnrichmentRequired }) => ({ uid, t, s, status, type, isDetectionHeaderEnrichmentRequired }))(payload)

				// update idb
				await $db.auth.clear()
				await $db.auth.add(payload)

				// update vuex
				store.commit('SET_AUTH', payload)
				await store.dispatch('ui/setIsWifi', { isWifi: payload.type === 'wifi' }, { root: true })

				// retrieve user profile if any, await api fetch must be after initialized
				if (store.state.uid) {
					await store.dispatch('users/get', { uids: store.state.uid }, { root: true })
				}
			} else {
				console.warn('[Authentication] failed to set authentication:')
				console.warn(result)
			}

			return result
		},
		async _validate (store, payload) {
			let result = { code: 2001, description: 'OK' }

			// validate multiple auth
			if (await $db.auth.count() > 1) {
				console.warn('fishy...')

				result = {
					code: 'E4006',
					description: 'multiple auth'
				}
			}

			return result
		},
		async _reinit (store) {
			let result = { code: 'E4001', description: 'Invalid Auth' }

			// update idb
			await $db.auth.clear()

			// update vuex
			await store.commit('SET_AUTH', { isInitialized: false })

			// clear cookie
			Object.keys(Cookies.get()).forEach(name => Cookies.remove(name))

			// reinitialize auth
			result = await store.dispatch('auth/_set', null, { root: true })

			return result
		},
		async _update (store) {
			let result = { code: 'E4009', description: 'Auth not updated' }

			if (store.state.isInitialized) {
				await store.commit('SET_AUTH', { ...store.state, isInitialized: false }) // temporarily uninitialized auth for api queue & etc

				try {
					let result = await store.dispatch('api/fetch', {
						urlParams: process.env.VUE_APP_URL_PARAMS_API_TOKEN_RENEW,
						method: 'POST',
						payload: []
					}, { root: true })

					if (result.code === 2001) {
						const auth = {
							...store.state,
							t: result.token,
							s: result.secret,
							isInitialized: true
						}

						result = await store.dispatch('auth/_set', auth, { root: true })
					}

					return result
				} catch (err) {
					console.warn(`[Authentication] failed to get token: ${err}`)
				}
			} else {
				result = await store.dispatch('auth/_awaitAuth', null, { root: true })
			}

			return result
		},
		async _awaitAuth (store) {
			let result = { code: 'E1001', description: '[Auth] retry failed: no authentication' }

			const retry = _ => {
				return new Promise((resolve, reject) => {
					let retryCount = 10

					const timeout = async _ => {
						if (store.state.isInitialized) {
							result = { code: 2001, description: 'OK' }
							resolve(result)
						} else {
							if (retryCount-- > 0) {
								setTimeout(timeout, 1000)
							} else {
								reject(result)
							}
						}
					}

					timeout()
				})
			}

			result = await retry()

			return result
		},
		async _getHeaderEnrichmentAuth () {
			return new Promise((resolve, reject) => {
				let frame = document.createElement('iframe')

				frame.setAttribute('src', `${process.env.VUE_APP_URL_PORTAL}/${process.env.VUE_APP_URL_PARAMS_PORTAL_DETECT_HEADER}`)
				frame.setAttribute('style', 'width: 0; height: 0; border: 0; border: none; display: none !important')
				frame = document.body.appendChild(frame)

				frame.onload = _ => {
					const getAuthResult = event => {
						window.removeEventListener('message', getAuthResult, true)
						if (frame.parentNode) { frame.parentNode.removeChild(frame) }

						const result = event.data

						if (result) {
							resolve(result)
						} else {
							reject(result)
						}
					}

					window.addEventListener('message', getAuthResult, true)
				}
			}).catch(error => console.warn(`[Authentication] failed to get header enrichment auth: ${error}`))
		},
		async _setHeaderEnrichmentAuth (store, payload) {
			if (payload.code === 2001 || payload.code === 'E3108') { // 2001 => Active/Suspended, E3108 => Inactive
				router.push({
					path: '/auth',
					query: payload
				})

				return
			}

			switch (payload.code) {
				case 'E3106': { // no msisdn detected
					if (store.state.uid) {
						await store.dispatch('auth/_reinit', null, { root: true }) // logout
					}

					Object.keys(Cookies.get()).forEach(name => Cookies.remove(name))
					break
				}

				case 'E3107': { // detected, no auth, data might be stale
					store.dispatch('auth/updateUser', null, { root: true })
					break
				}

				case 'E3109': { // msisdn detected but subscriber not existed
					await store.dispatch('ui/setIsWifi', { isWifi: false }, { root: true })
					break
				}
			}
		},
		async useHeaderEnrichment (store) {
			await store.dispatch('auth/_awaitAuth', null, { root: true })

			if (await $db.auth.count() === 0 || store.rootState.auth.isDetectionHeaderEnrichmentRequired) {
				const result = await store.dispatch('auth/_getHeaderEnrichmentAuth', null, { root: true })

				if (result) store.dispatch('auth/_setHeaderEnrichmentAuth', result, { root: true })
			}
		}
	}
}

export default auth
