const ui = {
	namespaced: true,
	state: {
		isWifi: JSON.parse(process.env.VUE_APP_FLOW_WIFI_IS_SUPPORTED || null),
		menu: {
			isOpen: false
		},
		modal: {
			isOpen: false,
			current: '',
			redirect: '',
			width: '',
			data: ''
		},
		placeholder: {
			thumbnailProfileUser: 'assets/images/placeholder-thumbnail-profile-user.png'
		},
		fab: {
			scrollTop: false
		}
	},
	getters: {
		getPlaceholder: state => payload => payload ? state.placeholder[payload.placeholder] : ''
	},
	mutations: {
		TOGGLE_MENU: (state, payload) => {
			state.menu.isOpen = payload === undefined ? !state.menu.isOpen : payload
		},
		TOGGLE_MODAL: (state, payload) => {
			state.modal.isOpen = payload === undefined ? !state.modal.isOpen : payload
		},
		SET_MODAL: (state, payload) => {
			state.modal.current = payload.name === undefined ? '' : payload.name
			state.modal.redirect = payload.redirect === undefined ? state.modal.redirect : payload.redirect
			state.modal.data = payload.data === undefined ? state.modal.data : payload.data

			// clear redirect & data if closed
			state.modal.redirect = payload.visible || payload.visible === undefined ? state.modal.redirect : ''
			state.modal.data = payload.visible || payload.visible === undefined ? state.modal.data : ''

			if (payload.visible === false) state.modal.width = ''
		},
		SET_MODAL_WIDTH: (state, payload) => {
			state.modal.width = payload.width === undefined ? !state.modal.width : payload.width
		},
		TOGGLE_FAB: (state, payload) => {
			state.fab[payload.fab] = payload.visible === undefined ? !state.fab[payload.fab] : payload.visible
		},
		SET_IS_WIFI: (state, payload) => {
			state.isWifi = payload.isWifi === undefined ? JSON.parse(process.env.VUE_APP_FLOW_WIFI_IS_SUPPORTED || null) : payload.isWifi
		}
	},
	actions: {
		setMenu: ({ commit }, payload) => commit('TOGGLE_MENU', payload),
		setModal: ({ commit }, payload) => {
			commit('SET_MODAL', payload)
			commit('TOGGLE_MODAL', payload.visible === undefined ? true : payload.visible)
		},
		setModalWidth: ({ commit }, payload) => commit('SET_MODAL_WIDTH', payload),
		toggleFab: ({ commit }, payload) => commit('TOGGLE_FAB', payload),
		setIsWifi: ({ commit }, payload) => commit('SET_IS_WIFI', payload)
	}
}

export default ui
