import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/store/api.js'
import i18n from '@/store/i18n.js'
import ui from '@/store/ui.js'
import auth from '@/store/auth.js'
import users from '@/store/users.js'
import operator from '@/store/operator.js'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		api,
		ui,
		i18n,
		auth,
		users,
		operator
	},
	strict: true
})
