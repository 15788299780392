import Vue from 'vue'
import VueIdb from 'vue-idb'

Vue.use(VueIdb)

export default new VueIdb({
	version: 1,
	database: 'fungamers',
	schemas: [
		{ i18n: '&locale, isDefault, *messages, *custom' },
		{ auth: '&uid, t, s, [t+s], status, type, isDetectionHeaderEnrichmentRequired' },
		{ users: '&uid, name, thumbnail, msisdn, points' }
	]
})
