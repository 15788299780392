import idb from '@/idb'
import { debounce } from 'lodash'

const $db = idb.db

const users = {
	namespaced: true,
	state: {
		users: {}
	},
	getters: {
		get: state => payload => {
			return payload.uid && state.users && state.users[payload.uid] ? state.users[payload.uid] : {}
		},
		auth: (state, getters, store) => state.users && store.auth.uid && state.users[store.auth.uid] ? state.users[store.auth.uid] : {}
	},
	mutations: {
		SYNC (state, payload) { state.users = payload }
	},
	actions: {
		sync: debounce(async ({ commit }) => {
			const users = {}

			for (const user of await $db.users.toArray()) {
				users[user.uid] = {
					name: user.name ? user.name : '',
					msisdn: user.msisdn ? user.msisdn : '',
					points: user.points ? user.points : '',
					thumbnail: user.thumbnail ? user.thumbnail : ''
				}
			}

			commit('SYNC', users)
		}, 100),
		async update (store, payload) {
			switch (typeof payload.uids) {
				case 'object': {
					await $db.users.bulkDelete(payload.uids)
					break
				}
				case 'string': {
					await $db.users.delete(payload.uids)
					break
				}
				default: {
					await $db.users.clear()
				}
			}

			const users = await store.dispatch('users/get', { uids: payload.uids }, { root: true })

			return users
		},
		async get (store, payload = {}) {
			// set default
			payload = {
				uids: [],
				...payload
			}

			payload.uids = Array.isArray(payload.uids) ? payload.uids : [payload.uids]

			// get local
			const users = await $db.users.filter(user => payload.uids.includes(user.uid)).toArray()

			// filter local found
			users.map(user => {
				payload.uids.map((uid, index) => {
					if (uid === user.uid) payload.uids.splice(index, 1)
				})
			})

			// get from server
			await Promise.all(
				payload.uids.map(async uid => {
					const result = await store.dispatch('users/_set', { uid: uid }, { root: true })

					if (result.code === 2001) {
						if (!store.rootState.auth.uid) {
							delete result.data.msisdn
							delete result.data.points
						}

						users.push(result.data)
					}
				})
			)

			return users
		},
		async _set (store, payload) {
			let result = { code: 'E4001', description: 'Invalid Result' }

			try {
				result = await store.dispatch('api/fetch', {
					method: 'POST',
					payload: [{
						user: [{
							get_profile: [{
								args: [{
									uid: payload.uid
								}]
							}]
						}]
					}]
				}, { root: true })

				result = result.user.get_profile[0][0]

				if (result.code === 2001) {
					// update idb
					await $db.users.put({
						uid: payload.uid,
						name: result.data.uname,
						thumbnail: result.data.uname_thumbnail === null ? '' : result.data.uname_thumbnail,
						msisdn: result.data.msisdn ? result.data.msisdn : '',
						points: result.data.points ? result.data.points : ''
					})

					// update vuex
					await store.dispatch('users/sync', null, { root: true })
				}
			} catch (err) {
				console.warn('[Users] failed to get user: ')
				console.warn(err)
			}

			return result
		}
	}
}

export default users
