const operator = {
	namespaced: true,
	state: {
		name: '',
		shortname: '',
		packages: {},
		trialPackage: {}
	},
	getters: {
		all: state => ({
			name: state.name,
			shortname: state.shortname,
			packages: state.packages,
			trialPackage: state.trialPackage
		})
	},
	mutations: {
		SET_OPERATOR: (state, { name, shortname, packages, trialPackage }) => {
			state.name = name || ''
			state.shortname = shortname || ''
			state.packages = packages || {}
			state.trialPackage = trialPackage || {}
		}
	},
	actions: {
		init: async (store, payload) => {
			const serviceModelName = 'service'
			const serviceControllerName = 'get_service'

			const responses = await store.dispatch('api/fetch', {
				method: 'POST',
				payload: [{
					[serviceModelName]: [{ [serviceControllerName]: [] }]
				}]
			}, { root: true })

			if (responses[serviceModelName][serviceControllerName][0][0].code === 2001) {
				const results = responses[serviceModelName][serviceControllerName][0][0].data

				const operator = { packages: {} }

				results.forEach(result => {
					// adapt api logic that repeats general data in
					operator.name = result.operator_name.toLowerCase()
					operator.shortname = result.operator.toLowerCase()
					operator.trialPackage = {
						type: result.freetrial_type,
						periodInDay: result.freetrial_cycle_date
					}

					operator.packages[result.subscription_package.toLowerCase()] = {
						price: result.currency.replace('{price}', result.subscription_fees),
						priceValue: result.subscription_fees,
						priceValueRenewal: result.renewal_fees,
						priceFormat: result.currency,
						subscribeUrl: result.subscription_url,
						periodInDay: result.cycle_date
					}
				})

				store.commit('SET_OPERATOR', operator)
			}
		}
	}
}

export default operator
