const api = {
	namespaced: true,
	actions: {
		async fetch (store, payload = {}) {
			let result = {}
			const params = {}
			let urlParams = ''

			urlParams = payload.urlParams || process.env.VUE_APP_URL_PARAMS_API_DISPATCH
			params.method = payload.method || 'GET'
			if (process.env.VUE_APP_URL_WEB && !process.env.VUE_APP_URL_WEB.includes('localhost')) params.credentials = 'include'
			params.headers = {}
			// params.headers = { 'Content-type': 'application/json' }

			// awaiting auth
			if (!store.rootState.auth.isInitialized && ![process.env.VUE_APP_URL_PARAMS_API_TOKEN, process.env.VUE_APP_URL_PARAMS_API_TOKEN_RENEW].includes(urlParams)) {
				result = await store.dispatch('auth/_awaitAuth', null, { root: true })

				// abort if failed
				if (result.code !== 2001) {
					return result
				}
			}

			// playground
			if (process.env.VUE_APP_PLAYGROUND) {
				params.headers['Playground'] = process.env.VUE_APP_PLAYGROUND
			}

			// bearer
			if (store.rootState.auth.s) {
				params.headers['Authorization'] = `Bearer ${store.rootState.auth.s}`
			} else {
				params.cache = 'no-store'
			}

			// content-type
			if (payload.contentType !== undefined) {
				params.headers['Content-type'] = payload.contentType
			}

			// body
			if (payload.payload !== undefined) {
				params.body = {
					payload: payload.payload
				}

				if (store.rootState.auth.t) {
					params.body.token = encodeURI(store.rootState.auth.t)
				}

				// stringify
				params.body = JSON.stringify(params.body)
			}

			// // body TODO: experimental
			// if (payload.payload !== undefined) {
			// 	if (params.method === 'GET') {
			// 		urlParams += '?payload=' + encodeURI(JSON.stringify(payload.payload))

			// 		if (store.rootState.auth.t) {
			// 			urlParams += '&token=' + encodeURI(store.rootState.auth.t)
			// 		}
			// 	} else {
			// 		params.body = {
			// 			payload: payload.payload
			// 		}

			// 		if (store.rootState.auth.t) {
			// 			params.body.token = encodeURI(store.rootState.auth.t)
			// 		}

			// 		// stringify
			// 		params.body = JSON.stringify(params.body)
			// 	}
			// }

			if (payload.DEBUG) {
				console.info('[DEBUG_API] Data')
				console.info(params)
			}

			try {
				// fetch
				result = await (await fetch(process.env.VUE_APP_URL_API + '/' + urlParams, params)).json()

				if (result.code) {
					const code = parseInt(result.code.toString().match(/\d/g).join(''))

					// error: auth related
					if (code > 4001 && code < 5000) {
						let requireReset = true

						if (result.code === 'E4007') { // expired
							result = await store.dispatch('auth/_update', null, { root: true })

							if (result.code === 2001) {
								requireReset = false

								console.log('re-fetch')
								result = await store.dispatch('api/fetch', payload, { root: true })
							}
						}

						// logout and reload for other auth related error
						if (requireReset) {
							store.dispatch('auth/logout', null, { root: true })
						}
					}
				}
			} catch (err) {
				if (payload.DEBUG) {
					console.info('[DEBUG_API] Result')
					console.info(result)
				}
				console.warn('[Api] fetch failed', err)
				result = err
			}

			return result
		}
	}
}

export default api
