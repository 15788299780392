import $i18n from '@/i18n'
import idb from '@/idb'
import * as Cookies from 'js-cookie'

const $db = idb.db

const i18n = {
	namespaced: true,
	state: {
		default: process.env.VUE_APP_LOCALE_DEFAULT,
		fallback: 'en-my',
		loaded: [],
		supported: process.env.VUE_APP_LOCALE_SUPPORTED.split(', '),
		direction: 'ltr'
	},
	getters: {
		isLoaded: state => locale => state.loaded.includes(locale),
		isSupported: state => locale => state.supported.includes(locale)
	},
	mutations: {
		SET_LOCALE (state, { locale }) {
			locale = !locale ? state.default : locale

			if (state.supported.includes(locale)) {
				state.default = locale

				if (!state.loaded.includes(locale)) {
					state.loaded.push(locale)
				}
			}
		},
		SET_DIRECTION (state, { direction }) {
			state.direction = direction || state.direction
		}
	},
	actions: {
		async setLocale (store, payload = {}) {
			// handle parameter
			if (!payload.locale || !store.getters['isSupported'](payload.locale)) {
				const idbI18nDefault = await $db.i18n.where('isDefault').equals('true').first()
				payload.locale = !idbI18nDefault ? store.state.default : idbI18nDefault.locale
			}

			// retrieve translation
			let messages = {}

			try {
				if (process.env.NODE_ENV === 'production') {
					messages = await (await fetch(`${process.env.VUE_APP_URL_CDN}/translations/get?locale=${payload.locale}`)).json()
				} else {
					messages = require(`@/assets/translations/${payload.locale}.json`)
				}
			} catch (err) {
				const json = await $db.i18n.where({ locale: payload.locale }).first()
				if (json !== undefined) {
					messages = json.messages
				}
			}

			// set locale
			if (Object.keys(messages).length > 0) {
				if (process.env.VUE_APP_SHORTNAME && process.env.VUE_APP_SHORTNAME !== 'fg') {
					messages.brand_name = process.env.VUE_APP_NAME
				}

				const customContent = messages.custom_content
				delete messages.custom_content

				// prepare locale
				if (!store.getters['isLoaded'](payload.locale)) {
					$i18n.setLocaleMessage(payload.locale, messages)
					$db.i18n.put({
						locale: payload.locale,
						messages: messages,
						custom: customContent
					})
				}

				// update idb
				if (payload.locale !== store.state.default) {
					$db.i18n.where({ locale: store.state.default }).modify({ isDefault: 'false' })
				}
				$db.i18n.where({ locale: payload.locale }).modify({ isDefault: 'true' })

				store.commit('SET_DIRECTION', { direction: ['ar-kw'].includes(payload.locale) ? 'rtl' : 'ltr' })
				document.querySelector('html').setAttribute('dir', store.state.direction)

				// update vuex
				store.commit('SET_LOCALE', payload)

				// update $i18n
				$i18n.locale = store.state.default

				// update cookie
				Cookies.set('locale', store.state.default)
			} else {
				// fail silently
			}
		}
	}
}

export default i18n
