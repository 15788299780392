<template>
	<component :is="layout" id="app"><!-- can be without #app -->
		<keep-alive :exclude="notAlive">
			<router-view></router-view>
		</keep-alive>
	</component>
</template>

<script>
import 'promise-polyfill/src/polyfill'
import 'whatwg-fetch'
import 'material-icons'
import 'normalize.css'
import 'default-passive-events'

export default {
	name: 'App',
	provide () {
		return {
			$util: {
				bindUrlCdn (urlParams = '', quality = 'd') {
					// qualities = ['d', 'c50', 'c100', 'c300', 'r50', 'r100', 'r300']
					// banner images only have r version, square images only have c version
					return `${urlParams.includes('http') ? '' : `${process.env.VUE_APP_URL_CDN}/`}${decodeURI(urlParams).replace('{dir}', quality)}`
				},
				scrollToTop (scrollDuration = 300) {
					const cosParameter = window.scrollY / 2
					let scrollCount = 0
					let oldTimestamp = performance.now()

					const step = newTimestamp => {
						scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp))
						if (scrollCount >= Math.PI) window.scrollTo(0, 0)
						if (window.scrollY === 0) return
						window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)))
						oldTimestamp = newTimestamp
						window.requestAnimationFrame(step)
					}
					window.requestAnimationFrame(step)
				}
			}
		}
	},
	data () {
		return {
			layouts: {
				'layout-default': _ => import('@/layouts/layout-default'),
				'layout-blank': _ => import('@/layouts/layout-blank')
			},
			notAlive: [
				'play'
			]
		}
	},
	methods: {
		grant (grant) {
			const isGranted = this.$store.getters['auth/isGranted']({ grant: grant })

			if (!isGranted) {
				this.$router.go(-1)
			}

			return isGranted
		}
	},
	computed: {
		layout () {
			return this.layouts[this.$route.meta.layout]
		}
	},
	async beforeCreate () {
		window.top.postMessage('ok', '*')

		this.$store.dispatch('users/sync')

		if (!window.location.pathname.includes('/auth')) {
			await this.$store.dispatch('auth/init')
		}

		if (this.grant) {
			this.grant(this.$route.meta.grant)
		}

		this.$router.beforeEach((to, from, next) => {
			if (this.grant) {
				next(this.grant(to.meta.grant))
			}
		})
	},
	async created () {
		if (window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches) {
			document.body.classList.add('pwa')
		}

		await this.$store.dispatch('operator/init') // get telco spec
		await this.$store.dispatch('i18n/setLocale') // use vuex default

		window.addEventListener('touchstart', function () {}, { passive: true })

		if (!window.location.pathname.includes('/auth') && JSON.parse(process.env.VUE_APP_HEADER_ENRICHMENT_IS_SUPPORTED || null)) {
			this.$store.dispatch('auth/useHeaderEnrichment')
		}

		await this.$store.dispatch('auth/updateUser')

		if (this.$route.path === '/' && JSON.parse(process.env.VUE_APP_MODAL_AOC_IS_SHOWN || null)) {
			this.$store.dispatch('auth/prompt')
		}
	}
}
</script>

<style lang="scss">
@import "@/assets/styles/app.scss";
</style>
